import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'
import { mainAPI,mainAPI_IP } from "@/API/mainAPI";

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listEvent:[],
      uploadRule:[
        v => !!v || 'Tidak Boleh Kosong'
      ]
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()
    // this.getDropbox().then(res => {
    //   console.log(res);
     
    // })
    
    this.G_GetAny('sakti/event').then(res => {
      let resData = res.data.content

      let listData = [];
      let arrSub = {};

      resData.forEach(v => {
          arrSub = {};

          arrSub['text'] = v.saktiEventNm;
          arrSub['value'] = v.saktiEventId;

          listData.push(arrSub);
      });

      this.listEvent = listData;

    })

   
  },
  components: {
    myTable
  },
  methods: {
   
    setDropbox(){

      Dropbox.choose({
        success: function(files) {
          console.log('file : '+files[0].link);

          let formDataDropbox = {
            "file" : files[0].link
          }

          mainAPI_IP.post('upload/emon/url', formDataDropbox, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            let resData = res.data;
            console.log(resData);
          })
          .catch(function(error) {
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("userLogin");
              window.location.replace("/login");
            } else {
              alert(error.response.data.message)
              // this.$snotify.success(error.response.message);
              // return error.response;
            }
          });
        },
        cancel: function() {
        },
        linkType: "direct", // or "direct"
        folderselect: false, // or true
    });
    },
    storeDataDropbox(dataFile){

      console.log(dataFile);
   
    },
    getTable() {
      this.loadingTb = true
      this.G_GetAny('reff/db/emon').then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      
      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true)
         
        this.G_PostAnyCustomIP('upload/emon', formData).then(res => {
          
          if (!res.status) {
            this.getTable();
            this.$snotify.success('Berhasil Upload Data');
     
          }else{
            this.$refs.notif.warning(res.data.message)
          }
        })
        .finally(() => {
          this.$refs.btnAct.load(false)
        });
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
  }
}