<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

          
             <template v-slot:item.crtdt="{item}">
                <div class="p-2">
                    {{G_formatDate(item.crtdt,'d-M-Y H:i:s')}}
                </div>
            </template>
            
        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'Tanggal Data', value: 'crtdt' },
                    { text: 'Nama File', value: 'filePath' },
                   ],
            }
        },
        mounted(){
           
        },
        methods: {
        
            getTable(){
                this.$emit('getTable');
            },
             edit(id){
                this.$emit('edit',id);
            },
             reset(id){
                this.$emit('reset',id);
            },
          
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>